import React from "react";
import "./PageContainer.less";

import { Layout } from "antd";
import PageHeader from "components/PageHeader";
import PageFooter from "components/PageFooter";
import headerImage from "../../assets/img/home_cabecalho_1920x324px.png";
import SocialsMedia from "components/SocialsMedia";
import MainMenu from "components/MainMenu";

const { Footer, Content } = Layout;

interface Props {
  complementMenu?: React.ReactNode;
}

const PageContainer: React.FC<Props> = ({ children, complementMenu }) => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <img
        style={{ width: "100%" }}
        src={headerImage}
        alt="Portal de especialização CGIS"
      />
      <PageHeader
        menu={<MainMenu />}
        actions={<SocialsMedia />}
        complementMenu={complementMenu}
      />
      <Content
        style={{
          height: "100%",
          backgroundColor: "var(--primary-color)",
        }}
      >
        <div style={{ margin: 16 }}>{children}</div>
      </Content>
      
      <Footer style={{ backgroundColor: "var(--background-color)" }}>
           <PageFooter /> 
      </Footer>
      
    </Layout>
  );
};

export default PageContainer;
