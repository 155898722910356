import React from "react";

function Buttons({ size = 50 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="1.5"
      clipRule="evenodd"
      height={size}
      width={size}
      viewBox="0 0 100 100"
    >
      <path
        fill="#177DDC"
        stroke="#fff"
        strokeWidth="1"
        d="M92.26 13.682a6.542 6.542 0 00-6.539-6.539h-72.59a6.542 6.542 0 00-6.539 6.539v13.079a6.542 6.542 0 006.539 6.539h72.59a6.542 6.542 0 006.539-6.539V13.682z"
        transform="translate(.574 29.779)"
      ></path>
      <path
        fill="#fff"
        fillRule="nonzero"
        d="M.259 0v-.631H.023v-.085h.568v.085H.354V0H.259z"
        transform="matrix(1.18816 0 0 .47068 11.019 35.565) matrix(8.33333 0 0 21.0362 22.387 37.75)"
      ></path>
      <path
        fill="#fff"
        fillRule="nonzero"
        d="M.097 0v-.519h.088V0H.097zM.092-.583L.157-.72h.115l-.107.137H.092z"
        transform="matrix(1.18816 0 0 .47068 11.019 35.565) matrix(8.33333 0 0 21.0362 27.477 37.75)"
      ></path>
      <path
        fill="#fff"
        fillRule="nonzero"
        d="M.258-.079l.013.078a.347.347 0 01-.067.008.149.149 0 01-.074-.015.084.084 0 01-.037-.04.32.32 0 01-.011-.104V-.45H.018v-.069h.064v-.128L.169-.7v.181h.089v.069H.169v.303a.15.15 0 00.005.048.031.031 0 00.015.017c.007.005.017.007.03.007.01 0 .023-.001.039-.004z"
        transform="matrix(1.18816 0 0 .47068 11.019 35.565) matrix(8.33333 0 0 21.0362 29.793 37.75)"
      ></path>
      <path
        fill="#fff"
        fillRule="nonzero"
        d="M.406 0v-.076a.192.192 0 01-.165.088.203.203 0 01-.085-.018.142.142 0 01-.06-.044.202.202 0 01-.027-.065.518.518 0 01-.005-.082v-.322h.088v.288c0 .046.002.077.005.093a.092.092 0 00.035.054c.018.013.04.02.067.02a.15.15 0 00.074-.02.118.118 0 00.049-.055.274.274 0 00.014-.102v-.278h.088V0H.406z"
        transform="matrix(1.18816 0 0 .47068 11.019 35.565) matrix(8.33333 0 0 21.0362 32.108 37.75)"
      ></path>
      <path
        fill="#fff"
        fillRule="nonzero"
        d="M0.064 -0.716H0.152V0H0.064z"
        transform="matrix(1.18816 0 0 .47068 11.019 35.565) matrix(8.33333 0 0 21.0362 36.743 37.75)"
      ></path>
      <path
        fill="#fff"
        fillRule="nonzero"
        d="M.033-.259c0-.096.027-.167.08-.214A.244.244 0 01.276-.53a.23.23 0 01.175.07.267.267 0 01.068.193c0 .067-.01.12-.03.158a.213.213 0 01-.087.089.26.26 0 01-.126.032.236.236 0 01-.176-.07.283.283 0 01-.067-.201zm.091 0c0 .066.014.116.043.149a.138.138 0 00.109.049c.044 0 .08-.016.109-.049.029-.034.044-.084.044-.152 0-.064-.015-.113-.044-.146a.139.139 0 00-.109-.05.139.139 0 00-.109.05c-.029.033-.043.082-.043.149z"
        transform="matrix(1.18816 0 0 .47068 11.019 35.565) matrix(8.33333 0 0 21.0362 38.594 37.75)"
      ></path>
    </svg>
  );
}

export default React.memo(Buttons);
