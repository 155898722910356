import React, { memo, useMemo, useState } from "react";
import "./MainMenu.less";

import { Button, Drawer, Space } from "antd";
import MaterialIcon from "icons/MaterialIcon";
import { mdiClose, mdiMenu } from "@mdi/js";
import MenuContent from "./MenuContent";
import useWindowSize from "hooks/useWindowSize";

interface Props {}
const MainMenu: React.FC<Props> = () => {
  const [width] = useWindowSize();
  const collapse = useMemo(() => width < 1260, [width]);

  const [menu, setMenu] = useState(false);

  return collapse ? (
    <>
      <Button
        onClick={() => setMenu(true)}
        icon={<MaterialIcon path={mdiMenu} style={{ fontSize: 18 }} />}
        type="text"
        title="Abrir menu de navegação"
      />
      <Drawer
        width={"70%"}
        visible={menu}
        closable={false}
        placement="left"
        maskClosable={false}
        onClose={() => setMenu(false)}
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3 style={{ margin: 0 }}>Navegação</h3>
            <Space>
              <Button
                icon={<MaterialIcon path={mdiClose} style={{ fontSize: 18 }} />}
                type="text"
                title="Fechar navegação"
                onClick={() => setMenu(false)}
              />
            </Space>
          </div>
        }
      >
        <section>
          <MenuContent mode="inline" />
        </section>
      </Drawer>
    </>
  ) : (
    <MenuContent />
  );
};

export default memo(MainMenu);
