import concorrencia_vaga from "assets/docs/anexos/Concorrencia_por_vaga.pdf";

export default [
  {
    name: "EQUIPE",
    key: "equipe",
    action: "internal",
    target: "equipe",
    subMenu: [
      {
        name: "COORDENAÇÃO",
        key: "coordenacao",
        action: "internal",
        target: "coordenacao",
      },
      {
        name: "EQUIPE DE PRODUÇÃO",
        key: "equipe_producao",
        action: "internal",
        target: "equipe_producao",
      },
      {
        name: "PROFESSORES CONTEUDISTAS",
        key: "professores_conteudistas",
        action: "internal",
        target: "professores_conteudistas",
      },
      {
        name: "PROFESSORES ORIENTADORES",
        key: "professores_orientadores",
        action: "internal",
        target: "professores_orientadores",
      },
    ],
  },
  {
    name: "CURSO",
    key: "curso",
    action: "internal",
    target: "curso",
    subMenu: [
      {
        name: "ESTRUTURA",
        key: "estrutura",
        action: "internal",
        target: "estrutura",
      },
      {
        name: "REGULAMENTO",
        key: "regulamento",
        action: "internal",
        target: "regulamento",
      },
    ],
  },
  {
    name: "ÁREA DO ALUNO",
    key: "area_aluno",
    action: "internal",
    target: "area_aluno",
    subMenu: [
      {
        name: "AMBIENTE VIRTUAL DE APRENDIZAGEM",
        key: "ambiente_virtual_aprendizagem",
        action: "external",
        target: "https://moodle2.ufg.unasus.gov.br/especializacao/",
      },
      {
        name: "SIGAA",
        key: "sigaa",
        action: "external",
        target: "https://ufgnet.ufg.br/cas/login/",
      },
      {
        name: "PIRILAMPOS",
        key: "pirilampos",
        action: "external",
        target: "https://pirilampos.programaeducacionalemsaudedigital.com/",
      }
    ],
  },
  {
    name: "PROCESSO SELETIVO",
    key: "processo_seletivo21",
    subMenu: [{
      name: "2021",
      key: "2021",
      subMenu: [
        {
          name: "CRONOGRAMA",
          key: "cronograma",
          action: "internal",
          target: "cronograma",
        },
        {
          name: "EDITAL",
          key: "edital",
          action: "internal",
          target: "edital",
        },
        {
          name: "LISTA FINAL DE INSCRITOS",
          key: "lista_final_inscritos",
          action: "internal",
          target: "lista_final_inscritos",

        },
        {
          name: "CONCORRÊNCIA POR VAGA",
          key: "concorrencia_vaga",
          action: "external",
          target: concorrencia_vaga,
        },
        {
          name: "MATRÍCULA 1ª CHAMADA",
          key: "intrucoes_uso",
          action: "internal",
          target: "intrucoes_uso",
        },
        {
          name: "MATRÍCULA 2ª CHAMADA",
          key: "segunda_chamada",
          action: "internal",
          target: "segunda_chamada",
        },
        {
          name: "RESULTADO FINAL FASE 1",
          key: "resultado_final",
          action: "internal",
          target: "resultado_final",
        },
        {
          name: "RESULTADO FINAL FASE 2",
          key: "resultado_final_fase_2",
          action: "internal",
          target: "resultado_final_fase_2",
        },
        {
          name: "RESULTADO FINAL FASE 3",
          key: "resultado_final_fase_3",
          action: "internal",
          target: "resultado_final_fase_3",
        }
      ]
    },
    {
      name: "2022",
      key: "csufg",
      action: "external",
      target: "https://centrodeselecao.ufg.br/2022/plssd/",
    }
    ],
  },
  {
    name: "MATERIAL",
    key: "ebooks_material",
    subMenu: [
      {
        name: "E-BOOKS",
        key: "ebooks",
        action: "internal",
        target: "ebooks",
      },
      {
        name: "DADOS ANALÍTICOS",
        key: "Dados Analíticos",
        action: "external",
        target: "https://app.powerbi.com/view?r=eyJrIjoiY2ZhMDM4NDItZDZiNi00ZmYxLTkyZmQtNjk1NDIwZDkxNjc0IiwidCI6ImIxY2E3YTgxLWFiZjgtNDJlNS05OGM2LWYyZjJhOTMwYmEzNiJ9",
      },
      { name: "MONOGRAFIAS DO CURSO", key: "Monografias do Curso", action: "internal", target: "monografias", }
    ], 
  },

  {
    name: "CONTATO",
    key: "contato",
    subMenu: [
      {
        name: "FALE CONOSCO",
        key: "fale_conosco",
        action: "internal",
        target: "fale_conosco",
      },
      {
        name: "LOCALIZAÇÃO",
        key: "localizacao",
        action: "internal",
        target: "localizacao",
      },
    ],
  },
] as Models.Menu[];
