import React, { memo } from "react";
import "./PageHeader.less";

import { Divider, Layout, Space } from "antd";

interface Props {
  menu?: React.ReactNode;
  actions?: React.ReactNode;
  complementMenu?: React.ReactNode;
}
const PageHeader: React.FC<Props> = ({
  menu = <></>,
  actions = <></>,
  complementMenu,
}) => {
  return (
    <Layout.Header className="page-header">
      <div>{menu}</div>

      <Space>
        {actions && actions}
        {complementMenu && (
          <>
            <Divider type="vertical" />
            {complementMenu}
          </>
        )}
      </Space>
    </Layout.Header>
  );
};

export default memo(PageHeader);
