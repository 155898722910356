import {
  mdiFacebook,
  mdiInstagram,
  mdiTwitter,
  mdiYoutube,
  mdiRadio,
  mdiTelevisionClassic,
} from "@mdi/js";
import MaterialIcon from "icons/MaterialIcon";
import React, { memo } from "react";
import "./SocialsMedia.less";

interface Props {}
const SocialsMedia: React.FC<Props> = () => {
  return (
    <div className="sociais-media-container">
      <a
        title="Acessar nosso youtube"
        href="https://www.youtube.com/channel/UCR7JHkmy_gJrr0_R5PtYkiA"
        rel="noopener noreferrer"
        target="_blank"
      >
        <MaterialIcon path={mdiYoutube} size={1} />
      </a>
      <a
        title="Acessar nosso facebook"
        href="https://www.facebook.com/CgisUfg"
        rel="noopener noreferrer"
        target="_blank"
      >
        <MaterialIcon path={mdiFacebook} size={1} />
      </a>
      <a
        title="Acessar nosso twitter"
        href="https://twitter.com/CgisUfg"
        rel="noopener noreferrer"
        target="_blank"
      >
        <MaterialIcon path={mdiTwitter} size={1} />
      </a>
      <a
        title="Acessar nosso instagram"
        href="https://www.instagram.com/cgisufg/"
        rel="noopener noreferrer"
        target="_blank"
      >
        <MaterialIcon path={mdiInstagram} size={1} />
      </a>
      <a
        title="Acessar a rádio UFG"
        href="https://radio.ufg.br/p/8051-radio-ao-vivo"
        rel="noopener noreferrer"
        target="_blank"
      >
        <MaterialIcon path={mdiRadio} size={1} />
      </a>
      <a
        title="Acessar a TV UFG"
        href="http://www.tvufg.org.br/"
        rel="noopener noreferrer"
        target="_blank"
      >
        <MaterialIcon path={mdiTelevisionClassic} size={1} />
      </a>
    </div>
  );
};

export default memo(SocialsMedia);
