import React from "react";

function ImageCards({ size = 50 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="1.5"
      clipRule="evenodd"
      height={size}
      width={size}
      viewBox="0 0 100 100"
    >
      <path
        fill="#F4F4F4"
        stroke="#fff"
        strokeWidth="0.78"
        d="M92.834 22.428c0-1.47-1.803-2.663-4.025-2.663H11.362c-2.221 0-4.025 1.193-4.025 2.663V53.06h85.497V22.428z"
        transform="matrix(1.002 0 0 1.51419 -.186 -22.777)"
      ></path>
      <path
        fill="#177DDC"
        stroke="#fff"
        strokeWidth="0.97"
        d="M92.834 19.765H7.337v30.554c0 1.513 1.299 2.741 2.899 2.741h79.781c1.555 0 2.817-1.193 2.817-2.663V19.765z"
        transform="matrix(1.002 0 0 1.05973 -.186 36.62)"
      ></path>
      <path
        fill="#177DDC"
        fillRule="nonzero"
        d="M469.333 384H128c-23.531 0-42.667-19.136-42.667-42.667v-256c0-23.53 19.136-42.666 42.667-42.666h341.333c23.531 0 42.667 19.136 42.667 42.666v256C512 364.864 492.864 384 469.333 384zM128 64c-11.755 0-21.333 9.579-21.333 21.333v256c0 11.755 9.578 21.334 21.333 21.334h341.333c11.755 0 21.334-9.579 21.334-21.334v-256c0-11.754-9.579-21.333-21.334-21.333H128z"
        transform="translate(0 -3.644) matrix(.09076 0 0 .09076 22.893 16.64)"
      ></path>
      <path
        fill="#177DDC"
        fillRule="nonzero"
        d="M192 192c-23.531 0-42.667-19.136-42.667-42.667 0-23.53 19.136-42.666 42.667-42.666 23.531 0 42.667 19.136 42.667 42.666C234.667 172.864 215.531 192 192 192zm0-64c-11.755 0-21.333 9.579-21.333 21.333 0 11.755 9.578 21.334 21.333 21.334 11.755 0 21.333-9.579 21.333-21.334 0-11.754-9.578-21.333-21.333-21.333zM97.493 361.173c-2.73 0-5.461-1.045-7.552-3.114-4.16-4.16-4.16-10.923 0-15.083l100.758-100.757c12.074-12.075 33.173-12.075 45.248 0l29.994 29.994 83.03-99.626a32.05 32.05 0 0124.362-11.52h.235a31.982 31.982 0 0124.299 11.157L509.44 302.4c3.84 4.459 3.328 11.2-1.152 15.04-4.459 3.84-11.179 3.349-15.04-1.152L381.675 186.112c-2.07-2.389-4.928-3.712-8.107-3.712-2.219-.192-6.123 1.344-8.192 3.84l-90.517 108.608a10.627 10.627 0 01-7.723 3.819c-3.029.213-5.909-.982-8.021-3.115l-38.251-38.251c-4.032-4.01-11.051-4.01-15.083 0L105.024 358.059a10.629 10.629 0 01-7.531 3.114z"
        transform="translate(0 -3.644) matrix(.09076 0 0 .09076 22.893 16.64)"
      ></path>
      <g>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M.259 0v-.631H.023v-.085h.568v.085H.354V0H.259z"
          transform="translate(0 -1.6) matrix(9.90135 0 0 9.90134 13.451 74.675)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M.097 0v-.519h.088V0H.097zM.092-.583L.157-.72h.115l-.107.137H.092z"
          transform="translate(0 -1.6) matrix(9.90135 0 0 9.90134 19.5 74.675)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M.258-.079l.013.078a.347.347 0 01-.067.008.149.149 0 01-.074-.015.084.084 0 01-.037-.04.32.32 0 01-.011-.104V-.45H.018v-.069h.064v-.128L.169-.7v.181h.089v.069H.169v.303a.15.15 0 00.005.048.031.031 0 00.015.017c.007.005.017.007.03.007.01 0 .023-.001.039-.004z"
          transform="translate(0 -1.6) matrix(9.90135 0 0 9.90134 22.25 74.675)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M.406 0v-.076a.192.192 0 01-.165.088.203.203 0 01-.085-.018.142.142 0 01-.06-.044.202.202 0 01-.027-.065.518.518 0 01-.005-.082v-.322h.088v.288c0 .046.002.077.005.093a.092.092 0 00.035.054c.018.013.04.02.067.02a.15.15 0 00.074-.02.118.118 0 00.049-.055.274.274 0 00.014-.102v-.278h.088V0H.406z"
          transform="translate(0 -1.6) matrix(9.90135 0 0 9.90134 25.001 74.675)"
        ></path>
        <path
          fill="#fff"
          d="M0.064 -0.716H0.152V0H0.064z"
          transform="translate(0 -1.6) matrix(9.90135 0 0 9.90134 30.508 74.675)"
        ></path>
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M.033-.259c0-.096.027-.167.08-.214A.244.244 0 01.276-.53a.23.23 0 01.175.07.267.267 0 01.068.193c0 .067-.01.12-.03.158a.213.213 0 01-.087.089.26.26 0 01-.126.032.236.236 0 01-.176-.07.283.283 0 01-.067-.201zm.091 0c0 .066.014.116.043.149a.138.138 0 00.109.049c.044 0 .08-.016.109-.049.029-.034.044-.084.044-.152 0-.064-.015-.113-.044-.146a.139.139 0 00-.109-.05.139.139 0 00-.109.05c-.029.033-.043.082-.043.149z"
          transform="translate(0 -1.6) matrix(9.90135 0 0 9.90134 32.708 74.675)"
        ></path>
        <path
          fill="#fff"
          fillOpacity="0.56"
          fillRule="nonzero"
          d="M.045-.23l.089-.008a.199.199 0 00.03.088.155.155 0 00.072.056.273.273 0 00.11.021.252.252 0 00.095-.016.129.129 0 00.062-.044.1.1 0 00.02-.061.093.093 0 00-.019-.059.141.141 0 00-.065-.042.937.937 0 00-.127-.035.644.644 0 01-.138-.045.196.196 0 01-.077-.067.168.168 0 01-.025-.089c0-.036.011-.07.031-.101a.198.198 0 01.09-.072.343.343 0 01.131-.024c.053 0 .1.009.141.026a.205.205 0 01.128.188l-.091.007A.143.143 0 00.453-.61C.425-.633.383-.645.328-.645c-.057 0-.099.011-.125.032a.094.094 0 00-.039.076.08.08 0 00.027.063.37.37 0 00.143.051c.077.018.13.033.159.046a.223.223 0 01.092.073c.02.03.03.063.03.102a.196.196 0 01-.033.108.221.221 0 01-.094.078.41.41 0 01-.301 0 .227.227 0 01-.103-.085.239.239 0 01-.039-.129z"
          transform="translate(0 -1.6) translate(13.451 85.984) scale(7.08333)"
        ></path>
        <path
          fill="#fff"
          fillOpacity="0.56"
          fillRule="nonzero"
          d="M.406 0v-.076a.192.192 0 01-.165.088.203.203 0 01-.085-.018.142.142 0 01-.06-.044.202.202 0 01-.027-.065.518.518 0 01-.005-.082v-.322h.088v.288c0 .046.002.077.005.093a.092.092 0 00.035.054c.018.013.04.02.067.02a.15.15 0 00.074-.02.118.118 0 00.049-.055.274.274 0 00.014-.102v-.278h.088V0H.406z"
          transform="translate(0 -1.6) translate(18.176 85.984) scale(7.08333)"
        ></path>
        <path
          fill="#fff"
          fillOpacity="0.56"
          fillRule="nonzero"
          d="M.147 0H.065v-.716h.088v.256a.173.173 0 01.142-.07c.032 0 .063.006.091.019a.188.188 0 01.07.054.268.268 0 01.044.084c.01.033.015.068.015.106a.296.296 0 01-.066.206.207.207 0 01-.158.073.166.166 0 01-.144-.077V0zM.146-.263c0 .062.008.107.025.135.028.045.065.067.113.067a.124.124 0 00.099-.05C.411-.144.425-.194.425-.26c0-.067-.013-.117-.04-.15a.123.123 0 00-.097-.048.127.127 0 00-.1.05C.16-.375.146-.326.146-.263z"
          transform="translate(0 -1.6) translate(22.115 85.984) scale(7.08333)"
        ></path>
        <path
          fill="#fff"
          fillOpacity="0.56"
          fillRule="nonzero"
          d="M.258-.079l.013.078a.347.347 0 01-.067.008.149.149 0 01-.074-.015.084.084 0 01-.037-.04.32.32 0 01-.011-.104V-.45H.018v-.069h.064v-.128L.169-.7v.181h.089v.069H.169v.303a.15.15 0 00.005.048.031.031 0 00.015.017c.007.005.017.007.03.007.01 0 .023-.001.039-.004z"
          transform="translate(0 -1.6) translate(26.055 85.984) scale(7.08333)"
        ></path>
        <path
          fill="#fff"
          fillOpacity="0.56"
          fillRule="nonzero"
          d="M.097 0v-.519h.088V0H.097zM.092-.583L.157-.72h.115l-.107.137H.092z"
          transform="translate(0 -1.6) translate(28.023 85.984) scale(7.08333)"
        ></path>
        <path
          fill="#fff"
          fillOpacity="0.56"
          fillRule="nonzero"
          d="M.258-.079l.013.078a.347.347 0 01-.067.008.149.149 0 01-.074-.015.084.084 0 01-.037-.04.32.32 0 01-.011-.104V-.45H.018v-.069h.064v-.128L.169-.7v.181h.089v.069H.169v.303a.15.15 0 00.005.048.031.031 0 00.015.017c.007.005.017.007.03.007.01 0 .023-.001.039-.004z"
          transform="translate(0 -1.6) translate(29.99 85.984) scale(7.08333)"
        ></path>
        <path
          fill="#fff"
          fillOpacity="0.56"
          fillRule="nonzero"
          d="M.406 0v-.076a.192.192 0 01-.165.088.203.203 0 01-.085-.018.142.142 0 01-.06-.044.202.202 0 01-.027-.065.518.518 0 01-.005-.082v-.322h.088v.288c0 .046.002.077.005.093a.092.092 0 00.035.054c.018.013.04.02.067.02a.15.15 0 00.074-.02.118.118 0 00.049-.055.274.274 0 00.014-.102v-.278h.088V0H.406z"
          transform="translate(0 -1.6) translate(31.958 85.984) scale(7.08333)"
        ></path>
        <path
          fill="#fff"
          fillOpacity="0.56"
          d="M0.064 -0.716H0.152V0H0.064z"
          transform="translate(0 -1.6) translate(35.898 85.984) scale(7.08333)"
        ></path>
        <path
          fill="#fff"
          fillOpacity="0.56"
          fillRule="nonzero"
          d="M.033-.259c0-.096.027-.167.08-.214A.244.244 0 01.276-.53a.23.23 0 01.175.07.267.267 0 01.068.193c0 .067-.01.12-.03.158a.213.213 0 01-.087.089.26.26 0 01-.126.032.236.236 0 01-.176-.07.283.283 0 01-.067-.201zm.091 0c0 .066.014.116.043.149a.138.138 0 00.109.049c.044 0 .08-.016.109-.049.029-.034.044-.084.044-.152 0-.064-.015-.113-.044-.146a.139.139 0 00-.109-.05.139.139 0 00-.109.05c-.029.033-.043.082-.043.149z"
          transform="translate(0 -1.6) translate(37.471 85.984) scale(7.08333)"
        ></path>
      </g>
    </svg>
  );
}

export default React.memo(ImageCards);
