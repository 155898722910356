import React, { memo, useCallback } from "react";
import "./MenuContent.less";

import { Divider, Menu } from "antd";
import mainMenuConfig from "configs/mainMenuConfig";
import { useHistory } from "react-router-dom";
import { navigateExternal } from "utils/navigateExternal";
interface Props {
  mode?:
    | "horizontal"
    | "vertical"
    | "vertical-left"
    | "vertical-right"
    | "inline";
}
const MenuContent: React.FC<Props> = ({ mode = "horizontal" }) => {
  const history = useHistory();

  const navigate = useCallback(
    (route: string) => {
      history.replace(`/p/${route}`);
    },
    [history]
  );

  const navSubItens = useCallback(
    (actionType: Models.ActionType, target: string) => {
      if (actionType === "external") {
        navigateExternal(target);
        setTimeout(() => {
          window.location.reload();
        }, 200);
      } else if (actionType === "internal") {
        navigate(target);
      }
    },
    [navigate]
  );

  return (
    <Menu className="main-menu-custom" mode={mode} triggerSubMenuAction="click">
      <Menu.Item onClick={() => navigate("home")}>HOME</Menu.Item>
      {mode !== "inline" && <Divider type="vertical" />}
      {mainMenuConfig.map((menuItem, i) => (
        <>
          {i !== 0 && mode !== "inline" && <Divider type="vertical" />}
          {menuItem.subMenu ? (
            <>
              <Menu.SubMenu key={i} title={menuItem.name}>
                {menuItem.subMenu.map((subMenuItem, j) => (
                  <>
                    {subMenuItem.subMenu ? (
                      <>
                        <Menu.SubMenu key={`${i}-${j}`} title={subMenuItem.name}>
                          {subMenuItem.subMenu.map((subSubMenuItem, k) => (
                            <Menu.Item
                              key={`${i}-${j}-${k}`}
                              onClick={() =>
                                navSubItens(
                                  subSubMenuItem.action,
                                  subSubMenuItem.target
                                )
                              }
                            >
                              {subSubMenuItem.name}
                            </Menu.Item>
                          ))}
                        </Menu.SubMenu>
                      </>
                    ) : (
                      <Menu.Item
                        key={`${i}-${j}`}
                        onClick={() =>
                          navSubItens(subMenuItem.action, subMenuItem.target)
                        }
                      >
                        {subMenuItem.name}
                      </Menu.Item>
                    )}
                  </>
                ))}
              </Menu.SubMenu>
            </>
          ) : (
            <Menu.Item
              onClick={() => navSubItens(menuItem.action, menuItem.key)}
              key={i}
            >
              {menuItem.name}
            </Menu.Item>
          )}
        </>
      ))}
    </Menu>
  );
};

export default memo(MenuContent);
