import React from "react";
import "./PeopleCard.less";
import { Row, Col, Typography } from "antd";
import userGeneric from "../../assets/avatars/user.png";

interface Props {
  title: string;
  content?: string;
  imageSrc?: string;
  gradientColor1?: string;
  gradientColor2?: string;
}
const PeopleCard: React.FC<Props> = ({
  title,
  content,
  imageSrc,
  gradientColor1,
  gradientColor2,
}) => {
  return (
    <Row
      className="card-people"
      gutter={16}
      style={{
        backgroundImage: `linear-gradient(120deg, #${
          gradientColor1 ? gradientColor1 : "bad8dc"
        }, #${gradientColor2 ? gradientColor2 : "d2e0e2"})`,
      }}
    >
      <Col span={9} className="image-container">
        <img
          className="image-card-people"
          src={imageSrc || userGeneric}
          alt="asdasd"
        />
      </Col>
      <Col span={13} className="people-infos">
        <div>
          {gradientColor1 || gradientColor2 ? (
            <Row gutter={16}>
              <Typography.Title
                style={{
                  color: `${
                    gradientColor2 || gradientColor2 ? "white" : "#404040"
                  }`,
                }}
                level={4}
              >
                {title}
              </Typography.Title>
            </Row>
          ) : (
            <>
              <Row gutter={16}>
                <Typography.Title
                  style={{
                    color: `${
                      gradientColor2 || gradientColor2 ? "white" : "#404040"
                    }`,
                  }}
                  level={4}
                >
                  {title}
                </Typography.Title>
              </Row>
              <Row gutter={16}>
                <h3
                  style={{
                    color: `${
                      gradientColor2 || gradientColor2 ? "white" : "#404040"
                    }`,
                  }}
                >
                  {content}
                </h3>
              </Row>
            </>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default PeopleCard;
